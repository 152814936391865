import React, { useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'

// import { CloseIcon } from './Components/Icons/CloseIcon'
import { Header } from './Components/Header/Header'
import { Discography } from './Components/Discography/Discography'
import { Home } from './Components/Home/Home'
import { Bio } from './Components/Bio/Bio'
import { Demo } from './Components/Demo/Demo'
import { Footer } from './Components/Footer/Footer'
import { NavOverlay } from './Components/Header/NavOverlay'

import { size, color } from './Styles/styles'

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 12px;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 100%;
    font-weight: normal;
    color: ${ color.primaryDark};
    background: ${ color.white };
  }

  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
  }

  h1, h2, h3 {
    font-family: 'Bebas Neue', sans-serif;
  }

  a {
    color: ${ color.secondary };
    text-decoration: none;
  }
`

const MainContainer = styled.main`
  margin: 0 auto;
  transition: all .5s;
  padding: 2rem;
  @media ${ size.medium } {
    margin-top: 2rem; 
    padding-left: 3rem;
    padding-right: 3rem;
  }
  max-width: 1200px;
`

export const Videos = (props) =>  <h1>Videos</h1>
export const Bands = (props) => <h1>Bands</h1>
         
export const Main = ({ children }) => {
  return (
    <MainContainer>
      { children }
    </MainContainer>
  )
}

export const navItems = {
    'home' : { title: 'Jon Hawes', linkText: 'Home', url: '/' },
    'bio' : { title: 'Bio', linkText: 'Bio', url: '/bio', heroImg: 'ml-toronto.jpg'   },
    'demo' : { title: 'Demo Reel', linkText: 'Demo Reel', url: '/demo', heroImg: 'studio1.png'   },
    'discography' : { title: 'Discography', linkText: 'Discography', url: '/discography' }
}

function App() {
  const [navOverlayActive, setNavOverlayActive] = useState(false)
  const toggleNav = () => setNavOverlayActive(!navOverlayActive)
  const location = useLocation()
  return (
    <React.Fragment>
      <GlobalStyle />
        <Header navItems={ navItems } toggleNav={ toggleNav } />
          <Switch location={ location } key={ location.key }>
            <Route exact path="/bio" component={ Bio } />
            <Route exact path="/demo" component={ Demo } />
            <Route exact path="/discography" component={ Discography } />
            <Route exact path={["/home", "/"]} component={ Home } />
            <Route component={ Home } />
          </Switch>
        <Footer />
        <NavOverlay navItems={ navItems } navOverlayActive={ navOverlayActive } toggleNav={ toggleNav } /> 
    </React.Fragment>
  );
}

export default App;
