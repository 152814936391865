import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { motion } from 'framer-motion'
import styled from 'styled-components'

import { Main } from '../../App'
import { Hero } from '../Hero/Hero'
import { Album } from './Album'
import { ArtistFilter } from './ArtistFilter'

import { size, color, MainHeadingShadowed, ContentBlurb } from '../../Styles/styles'

const CustomMainHeading = styled(MainHeadingShadowed)` 
  top: 300px;
  @media ${ size.medium } {
    left: 10px;
    top: 250px;
  }
`
const AlbumList = styled(motion.section)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

`
// const ContentBlurb = styled.p`
//   font-family: 'Pontano Sans', sans-serif;
//   text-align: center;
//   font-size: 1.5rem;
//   margin: 2rem;
//   padding-bottom: 2rem;
//   border-bottom: 1px solid ${ color.primary };
// `
const list = {
  visible: {
    transform: 'scale(1)',
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.075,
    },
  },
  hidden: {
    transform: 'scale(.1)',
    transition: {
      when: "afterChildren",
    },
  },
}

export const Discography = (props) => {
  const [filter, setFilter] = useState('all')
  const [albums, setAlbums] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}albums/`)
    .then(resp => {
      setAlbums(resp.data)
      setLoading(false) 
    })
    .catch(err => {
      setLoading(false) 
      setError(err.message)
      console.log(err)
    })
  }, [])

  return (
    <React.Fragment>
      <Hero 
        heroImage='jh_studio1.jpg' 
        heroCredit={ {'name': 'Jackson Tyler Eddy', 'url':'https://instagram.com/jacksontylereddy'} }>
        <CustomMainHeading>Discography</CustomMainHeading>  
      </Hero>
      <Main>
        <ContentBlurb>I've been fortunate to have made some great records with amazing artists over the years. Here are some of the highlights:</ContentBlurb>
        { error && 
          <div>{ error }</div>
        }
        
        { loading && 
          <div>LOADING...</div>
        }
        { albums && 
        <>
        <ArtistFilter albums={ albums } setFilter={ setFilter }  /> 

        <AlbumList
          initial="hidden"
          animate="visible"
          variants={ list }
        >
          { 
          (filter !== 'all' ? albums.filter((album) => album.artist.name === filter) : albums)
          .map((album, i) => <Album key={ i } album={ album } /> )}
        </AlbumList>
        </>
        }
      </Main>
    </React.Fragment>
  )
  // }
}