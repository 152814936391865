import React from 'react'
import styled from 'styled-components'
import { size, color } from '../../Styles/styles'

const Container = styled.section`
  height: 500px;
  width: auto;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $color-black;
  background-position: -125px 0;

  @media ${ size.medium } {
    height: minmax(200px, 80vh);
    background-position: 0 0;
  }
`
// special case for Home Page hero, stretches viewport height
const FullscreenContainer = styled(Container)`
  height: 100vh;
  background-size: auto 100vh;
  background-position-x: -150px;
  background-color: black;

  @media ${ size.medium } {
    background-size: cover;
    background-position: 0 0;
  }
`

const Credit = styled.div`
  text-align: right;
  margin-right: 1rem;
  > p {
    font-style: italic;
    font-size: 1rem;
    > a {
      color: ${ color.secondary }
    }
  }
  
`

export const Hero = ({ heroImage, heroCredit = null, children, fullscreen = false }) => {
  const imgUrl =  `/images/${ heroImage }`
  
  return (
    <>
    { fullscreen ?
    <FullscreenContainer style={{ backgroundImage: `url(${ imgUrl })`}}>
      { children }
    </FullscreenContainer>
    :
    <Container style={{ backgroundImage: `url(${ imgUrl })`}}>
      { children }
    </Container>
    }

    { heroCredit && 
      <Credit>
        <p>Photo by <a href={ heroCredit.url } target="_blank" rel="noopener noreferrer">{ heroCredit.name } </a></p>
      </Credit>
    }
    </>
  )
  
}
