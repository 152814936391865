import React from 'react';
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { color } from '../../Styles/styles'
import { CloseIcon } from '../Icons/CloseIcon'

const Container = styled.aside`
  z-index: 100;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: -1000px;
  left: 0;
  background-color: ${ color.primaryDark }ee;
  color: #eee;
  transition: all .5s;
  ${ props => props.visible ? 'transform: translateY(1000px);' : ''}
`

const Header = styled.div`
  background-color: ${ color.primary };
  padding: 1rem;  
  display: flex;    
  flex-direction: row-reverse;
  justify-content: space-between;
`
const Body = styled.nav`
  text-align: center;
  margin: 5rem;
`
const LinkElem = styled(NavLink)`
  display: block;
  font-size: 2rem;
  margin-bottom: 2rem;
  color: ${ color.white };
  transition: color .25s;
  &:hover {
    color: ${ color.secondary };
  }
`

export const NavOverlay =  ({ navItems, navOverlayActive, toggleNav } ) => {

  return (  
    <Container visible={ navOverlayActive } >
      <Header>
        <CloseIcon onClick={ toggleNav }  />
      </Header>
      <Body>
        {Object.keys(navItems).map((item, i) => <LinkElem key={ navItems[item].url } onClick={ toggleNav } to={ navItems[item].url }>{ navItems[item].linkText }</LinkElem> )}
      </Body>
    </Container>
  )
}