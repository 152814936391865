import React from 'react';
import InstagramIcon from '@material-ui/icons/Instagram'

export const InstaIcon = () => {

  return (
    <InstagramIcon style={{ fontSize: '3rem', margin: '1rem', color: '#fff', height: '100%', align: 'center' }} />
  )
}

