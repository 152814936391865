import React from 'react'
import styled from 'styled-components'

import { color, size } from '../../Styles/styles'

const Inner = styled.span`
  display: flex;
  flex-direction: column; 
  max-width: 1152px;

  margin: .5rem auto;
  & a {
    color: ${ color.primary };
  }

  @media ${ size.medium } {
    flex-direction: row;
  justify-content: space-between; 
  }

`

const Container = styled.footer`
  width: 100%;
  padding: 1rem;
  background-color: #ccc;
 

  

 
`

export const Footer = (props) => {

  return (
    <Container>
    <Inner>
      
      <span>Designed &amp; implemented by Jon Hawes</span>
      <a href="mailto:jonathan@jonhawes.com">jonathan@jonhawes.com</a>
      
    </Inner>
    </Container>
  )
}