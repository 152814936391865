import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Navigation } from './Navigation'
import { Hamburger } from '../Icons/Hamburger'
import { Socials } from './Socials'
import { color } from '../../Styles/styles'

const Container = styled.header`
  position: fixed;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background: ${props => props.showBackground ? color.primaryDark : 'none'};
  opacity: ${props => props.showBackground ? '.9' : '1'};
  z-index: 80;
  transition: background .5s;
`

export const Header = ({ navItems, currentPage, navOverlayActive, toggleNav }) => {
  // mechanism for header background effect
  const [windowIsAtTop, setWindowIsAtTop] = useState(false)
  
  // scroll listener for header background effect
  useEffect(() => {
      window.onscroll = function() {
        let currentScrollPos = window.pageYOffset;
        setWindowIsAtTop(currentScrollPos > 0)
      }
    },
    []
  )

  return (
    <Container 
      showBackground={ windowIsAtTop }
      onMouseOver={() => setWindowIsAtTop(true)}
    >
      <Navigation navItems={ navItems } navOverlayActive={ navOverlayActive } />
      <Hamburger toggle={ toggleNav } />
      <Socials />
    </Container>
  )
} 