import React from 'react';
import styled from 'styled-components'

import { color, size } from '../../Styles/styles'

const Container = styled.div`
  margin: 0 0 1rem 1rem;
  cursor: pointer;
  @media ${ size.medium } {
    display: none;
  }
`
const HamburgerLevel = styled.div`
    width: 35px;
    height: 5px;
    background-color: ${ color.white };
    margin: 6px 0;
`

export const Hamburger = ({ toggle }) => {
  return (
    <Container onClick={ toggle }>
      <HamburgerLevel />
      <HamburgerLevel />
      <HamburgerLevel />
    </Container>
  )
}