import React from 'react'; 
import { motion } from 'framer-motion'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { size, color } from '../../Styles/styles'

const Container = styled(motion.nav)`
  padding: .5rem;
  display: none;
  @media ${ size.medium } {
    display: flex;
    margin: auto 0;
  }
`

const NavElem = styled(NavLink)`
  font-family: 'Bebas Neue', sans-serif;
  color: ${ color.white };
  text-decoration: none;
  padding: .5rem;  
  margin: .3rem;
  font-size: 1.75rem;
  transition: color .25s ease-in-out;
  &:hover {
    color: ${ color.secondary };
  }
  &.active {
    color: ${props => color.secondary }
  }
`

export const Navigation = ({ navItems }) => {
  const navigationVariants = {
    hidden: {
      opacity: 0
    },
    visible: {
      opacity: 1
    },
    transition: {
      delay: 1
    }
  }

  const childVariants = {
    hidden: {
      opacity: 0, 
      scale: 1
    },
    visible: { 
      opacity: 1,
      scale: [1.2, 1]
    }
  }

  return ( 
      <Container 
        variants={ navigationVariants }
        initial="hidden"
        animate="visible"
        transition={{ 
          delay: 1.75,
          duration: 1,
          when: "beforeChildren",
          staggerChildren: .05 }}
      >
     
      { Object.keys(navItems).map((key, i) => {
        return (
          
          <motion.div
            key={ key }
            variants={ childVariants }            
          >
            <NavElem 
              exact
              key={navItems[key].url}
              to={navItems[key].url} 
            >{navItems[key].linkText}</NavElem>
          </motion.div>
        )
 } )}
      </Container>
    )
}