import React from 'react'
import { InstaIcon } from '../Icons/InstaIcon'

export const Socials = props => {

  return (
    <div>
      <a href="https://www.instagram.com/jahnnypeace/" rel="noopener noreferrer" target="_blank"><InstaIcon /></a>
    </div>
  )
}