import React from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { color, font } from '../../Styles/styles'

const width = '250px'

const DropdownContainer = styled.div`
  width: 10.5em;
`

const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${width};
  padding: 0.4em 1em 0.4em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1.3rem;
  color: ${color.primary};
  border: 2px solid #e5e5e5;
  border-bottom: 0;
  background: #ffffff;
  cursor: pointer;
`

const DropdownArrow = styled.div`
  position: relative;
  height: 15px;
  width: 15px;
  &::before, &::after {
    content: "";
    position: absolute;
    bottom: 0px;
    width: 0.15rem;
    height: 100%;
    transition: all 0.25s;    
  }
  &::before {
    left: -5px;
    transform: ${props => props.isOpen ? 'rotate(45deg)' : 'rotate(-45deg)'};
    background-color: ${color.primary};
  }
  &::after {
    left: 5px;
    transform: ${props => props.isOpen ? 'rotate(-45deg)' : 'rotate(45deg)'};
    background-color: ${color.primary};
  }
`

const DropdownListContainer = styled.div`
  position: absolute;
  z-index: 100;
  visibility: ${props => props.visible ? 'visible' : 'hidden' };
`

const DropdownList = styled.ul`
  width: ${width};
  padding: 0;
  margin: 0;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  border-top: 0;
  box-sizing: border-box;
  color: #3faffa;
  font-size: 1.3rem;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
`

const ListItem = styled.li`
  list-style: none;
  font-family: ${font.primary};
  color: ${props => props.selected ? color.secondary : color.primary};
  margin-bottom: 0.8em;
  padding: .75rem;
  cursor: pointer;
  &:hover {
    background-color: ${ color.secondary };
    color: white;
  }
`
const ClickOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  visibility: ${props => props.visible ? 'visible' : 'hidden'};
  z-index: 99;
  // background-color: rgba(0,0,0,.25);
`

export const CustomSelect = ({ handleChange, data }) => {
  
  const [isOpen, setIsOpen] = React.useState(false)
  const toggle = () => setIsOpen(!isOpen)  
  // set default option to first item 
  const [selectedOption, setSelectedOption] = React.useState(data[0])

  const onListItemClicked = (item) => {
    setIsOpen(false)
    setSelectedOption(item)
    handleChange(item.value)
  }

  return (
    <DropdownContainer>
      { createPortal(
          <ClickOverlay visible={ isOpen } onClick={ () => isOpen ? toggle() : ()=>{} }>&nbsp;</ClickOverlay>
          , document.body) }
      <DropdownHeader onClick={ toggle }>
        { selectedOption.label  }
        <DropdownArrow isOpen={ isOpen } />
      </DropdownHeader>
      <DropdownListContainer visible={ isOpen }>
        <DropdownList>
          { data.map((item, i) => {
             return (
              <ListItem 
                onClick={ () => onListItemClicked(item) } 
                selected={ item.value === selectedOption.value }
                key={ i }
              >{ item.label }</ListItem>)
          }) }
        </DropdownList>
      </DropdownListContainer>
    </DropdownContainer>



  // <div>
      // <select onChange={ (e) => handleChange(e) }>
      //   <option value="all">-- All --</option>
      //   { data.map(item => <option key={ item.value } value={ item.value }>{ item.label }</option>) }
      // </select> 
    // </div>
   )
}