import React from 'react'
import styled from 'styled-components'
import { color, size, curve } from '../../Styles/styles.js'
import { SpotifyIcon, InstagramIcon, YouTubeIcon } from './MediaIcons'

const Container = styled.article`
  position: relative;
  flex-basis: 100%;
  margin: 1rem 0;
  width: 100px;
  border-radius: 10px;
  transition: all .1s ${ curve.primary };
  color: ${ color.white };  
  overflow: hidden;

  @media ${ size.medium } {
    flex-basis: 49%;    
  }

  @media ${ size.large } {
    flex-basis: 32%;    
  }

  & img {
    width: 100%; 
    height: auto;
  }
`
const DetailOverlay = styled.div`
  position: absolute;
  // top: -1000px;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
  transition: all .5s ${ curve.primary };
  z-index: 100;
  height: 100%;
  width: 100%;
  padding: 1.5rem;
  background-color: rgba(0,0,0,0.75);
  ${ Container }:hover & {
    // top: 0;
    opacity: 1;
  }
  & * {
    color: ${ color.secondary };
  }
  & h2 { 
    margin: 0;
    font-size: 3rem;  
  }
  & li {
    color: ${ color.white };
    &:nth-child(1) {
      font-size: 2rem;
      color: ${ color.white };
    }
  }
  & p {
    color: ${ color.white };
    font-size: 1.5rem;
  }
  & hr {
    color: ${ color.primary };
  }
  & svg {
    margin: 0;
  }
`
const DataGroup = styled.div`
   & * { 
     margin-top: 1rem;
    }

    > 
`

const IconGroup = styled.aside`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  border-top: 1px solid ${ color.secondary }; 
  padding-top: 1rem;
`

const AlbumDetails = styled.ul`
  // display: flex;
  width: 100%;
  justify-content: space-between;
  padding-right: 1rem;
  list-style-type: none;
`

const AlbumTitle = styled.h2`
  color: ${ color.secondary };
  font-size: 1.75rem;
`
const ArtistName = styled.li`
 color: ${ color.primaryDark };
  font-size: 1.5rem;
`

const AlbumYear = styled.li`
  color: ${ color.primaryDark };
  font-size: 1rem;
`


export const Album = ({ album, toggle }) => {

  return (
    <Container 
      key={ album.id } 
      onClick={ toggle }
      >        
      <img  
        src={`${process.env.PUBLIC_URL}/images/discography/${album.image}`} 
        alt={album.title} 
      />
      <DetailOverlay> 
        <DataGroup>
        <AlbumTitle>{ album.title }</AlbumTitle>
        <AlbumDetails>
          <ArtistName>{ album.artist.name }</ArtistName>
          <AlbumYear>{ album.year }</AlbumYear>
        </AlbumDetails>
        <p>{ album.description }</p>
        </DataGroup>
        <IconGroup>
            { album.url1 ? <SpotifyIcon url={ album.url1 } /> : "" }
            { album.url2 ? <YouTubeIcon url={ album.url2 } /> : "" }
            { album.url3 ? <InstagramIcon url={ album.url3 } /> : "" }
        </IconGroup>
      </DetailOverlay>        
    </Container>
  )
}