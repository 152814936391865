import React from 'react';
import styled from 'styled-components'

import { VideoContainer } from '../../Styles/styles'

const Container = styled.article`
  margin: 2rem 1rem;  

  & h3 {
    font-size: 2rem;
    margin: 1rem 1rem 0 1rem;
    font-family: 'Pontano Sans';
    color: #666;
  }

  & p {
    font-family: 'Pontano Sans';
    font-size: 1.5rem;
    margin-left: 12px;
    margin-bottom: 12px;
    line-height: 2rem;
  }
`

export const VideoCard = ({ title, url, text = '', director = '' }) => {
  
  return (
    <Container>
      <h3>{ title }</h3>
      { text.length ? 
        <p>{ text }<br />
        Directed by { director }</p>
      : <p> { director.length ?<>Directed by { director }</> : '' }</p>
      }
      
      <VideoContainer>
          <iframe 
            title="First Hawaiian Bank Spot"
            width="560" 
            height="315" 
            src={ url }
            frameBorder="0" 
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen></iframe>
      </VideoContainer>
    </Container>
  )
}