import React from 'react'
import styled from 'styled-components'

const MediaIcon = styled.div`
  width: 75px; 
  height: 50px; 
`

export const SpotifyIcon = ({ url }) => {
  return (
    <MediaIcon>
      <a href={ url } target="_blank" rel="noopener noreferrer"><img src="images/Spotify-Icon-Logo.wine.png" alt="Spotify Icon"/></a>
    </MediaIcon>
  )
}

export const YouTubeIcon = ({ url }) => {
  return (
    <MediaIcon> 
       <a href={ url } target="_blank" rel="noopener noreferrer"><img src="images/YouTube-Icon-White-Logo.wine.png" alt="YouTube Icon" /></a>
    </MediaIcon>
  )
}

export const InstagramIcon = ({ url }) => {
  return (
    <MediaIcon>
       <a href={ url } target="_blank" rel="noopener noreferrer"><img src="images/Instagram-Glyph-White-Logo.wine.png" alt="Instagram Icon" /></a>
    </MediaIcon>
  )
}
