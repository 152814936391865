import React from 'react';
import ClearIcon from '@material-ui/icons/Clear'
import styled from 'styled-components'

const Icon = styled(ClearIcon)`
   font-size: 7rem;
   font-weight: bold;
   cursor: pointer;
`

export const CloseIcon = ({ onClick }) => {
  return (
    <Icon onClick={ onClick } style={{ fontSize: '3rem' }} />
  )
}


