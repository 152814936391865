import styled from 'styled-components'

export const shadow = {
  small: '0px 10px 20px -8px rgba(0,0,0,0.46)',
  medium: '10px 7px 39px -8px rgba(0,0,0,0.60)'
}

export const color = {
  white: '#fff',
  black: '#141414',
  salmon: '#F8B195',
  red: '#F67280',
  brown: '#C06C84',
  purple: '#9A99BF',
  blue: '#355C7D',
  green: '#3FA6B1',
  yellow: '#FFFFEE',

  secondary: '#EE8124',
  secondaryLight: '#FEC872',
  secondaryDark: '#B67102',
  primary: '#5D737E',
  primaryLight: '#8A9EA8',
  primaryDark: '#3D4B52'
}

export const size = {
  small: '(max-width: 599px)',
  medium: '(min-width: 600px)',
  large: '(min-width: 992px)',
  xlarge: '(min-width: 1200px)'
}

export const curve = {
  primary: 'cubic-bezier(0.5,1.21,1,0.99)'
}

export const font = {
  primary: 'Titillium Web',
  heading: "'Bebas Neue', sans-serif"
}

export const MainHeading = styled.h1`
  font-family: ${ font.heading };
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  letter-spacing: 8px;
  text-align: center;
  font-size: 5rem;
  color: ${color.yellow};
  @media ${size.medium} {
    font-size: 9rem;
    top: 25vh;
    left: auto;
    right: 10%;
    transform: none;
  }
  @media ${size.large} {
    font-size: 10rem;
    top: 25vh;
    left: auto;
    right: 10%;
  }
`
export const MainHeadingShadowed = styled(MainHeading)`
  text-shadow: 1px 1px 20px #333;
`

export const SubHeading = styled(MainHeading)`
  display: flex;
  flex-direction: column;
  font-size: 3rem;
  text-align: center;
  top: 75%;
  @media ${size.medium} {
    top: 50vh;
    left: auto;
    right: 10%;
    text-align: right;
    font-size: 5rem;
  }
  @media ${size.large} {
    font-size: 6rem;
    top: 50vh;
    left: auto;
    right: 10%;
  }
`
export const SectionHeading = styled.h3`
  font-size: 4rem;
  text-align: center;
  color: ${ color.green };
`
export const SectionContent = styled.p`
  font-family: 'Pontano Sans', sans-serif;
  font-size: 1.5rem;
  margin: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid ${ color.primary };
  > p {
    margin-bottom: 1rem;
  }
`

export const ContentBlurb = styled(SectionContent)`
  text-align: center;
`
export const VideoContainer = styled.div`
  position: relative;
  width: 80%;
  padding-bottom: 56.25%;
  height: 0;
  margin: 0px 1rem;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`