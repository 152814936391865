import React from 'react'
import { CustomSelect } from '../Utils/CustomSelect'

export const ArtistFilter = ({ albums, setFilter }) => {

  const handleChange = (value) => setFilter(value)
  
  // transform artistData into deduped value/label list for pull-down
  const pulldownData = albums.reduce((result, item) => {
    // transform 
    const current = { value: item.artist.name, label: item.artist.name}
    // dedupe 
    const deduped = (result.find(element => element.value === current.value)) ? result : [...result, current]
    return deduped
    }, [])

  return (
    <div>
      <h1>Filter Artists</h1>
      <CustomSelect 
        handleChange={ handleChange } 
        data={ [{'value': 'all', 'label': '--All--'}, ...pulldownData] } />
    </div>
  )
}
