import React from 'react'
import { motion } from 'framer-motion'

import { Main } from '../../App'
import { Hero } from '../Hero/Hero'
import {  MainHeading, 
          SubHeading, 
          SectionHeading, 
          SectionContent, 
          VideoContainer,
          color
        } from '../../Styles/styles'


export const Home = (props) => {
  const headingVariants = {
    hidden: {
      opacity: 0
    },
    visible: {
      opacity: 1,
      transition: {
        delay: .75,
        duration: 1,
        when: 'beforeChildren',
        staggerChildren: 0.25
      }
    }  
  }

  const childVariants = {
    hidden: {
      opacity: 0,
      scale: 2
    },
    visible: {
      opacity: 1,
      scale: [1.2, 1]
    }
  }

  return (
  
  <React.Fragment>
      <Hero heroImage='jh_portland_2_1620.jpg' fullscreen>
        <motion.div variants={ headingVariants }
            initial="hidden"
            animate="visible">
          <MainHeading 
            exit={{ x: '-100vw',  transition: { delay: 0 } }}
          >
            <span style={{ color: color.secondary }}>J</span>on <span style={{ color: color.secondary }}>H</span>awes
          </MainHeading>
          <SubHeading>
            <motion.a variants={ childVariants } href="/bio" whileHover={{ scale: 1.1 }} whileTap={{ scale: 1.1 }}><span style={{ color: color.yellow }} >bassist</span></motion.a>
            <motion.a variants={ childVariants } href="/demo" whileHover={{ scale: 1.1 }} whileTap={{ scale: 1.1 }}><span style={{ color: color.green }} whileHover={{ scale: 1.1 }}>composer</span></motion.a>
            {/* <motion.a variants={ childVariants } href="#" whileHover={{ scale: 1.1 }} whileTap={{ scale: 1.1 }}><span className="orange" whileHover={{ scale: 1.1 }}>developer</span></motion.a> */}
          </SubHeading>
        </motion.div>
      </Hero>
      <Main>
      <section>
        <SectionHeading>Hey there!</SectionHeading>
        <SectionContent>I'm Jon Hawes, I play bass, I've <a href="/demo">composed music</a> for commercials and film, and I'm also a web applications developer.</SectionContent>
      </section>
      <section>
        <SectionHeading>What's New</SectionHeading>
        <SectionContent>I'm pretty excited to see a preview of the release of Mike Love's new live concert film "Mike Love and the Full Circle at Home in Hawaii". 
          <br />You can view this on Mike's <a href="https://www.youtube.com/channel/UCf3r4Ti2ezy9yW0MFoCgLfQ" rel="noopener noreferrer" target="_blank">YouTube channel.</a>
        </SectionContent>
          <VideoContainer>
            <iframe 
              title="Mike Love At Home in Hawaii "
              width="560" 
              height="315" 
              src="https://www.youtube.com/embed/-zaQzAli9SY" 
              frameborder="0" 
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen></iframe>
          </VideoContainer>
      </section>
     
      </Main>
  </React.Fragment>
 
  )
}