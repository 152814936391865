import React from 'react';

import { Main } from '../../App'
import { Hero } from '../Hero/Hero'
import { MainHeadingShadowed, SectionHeading, SectionContent } from '../../Styles/styles'

export const Bio = (props) => {

  return (
    <React.Fragment>
    <Hero heroImage="jh_singit.jpg">
      <MainHeadingShadowed>Bio</MainHeadingShadowed>
    </Hero>
    <Main>
      <SectionHeading>All About Me</SectionHeading>
      <SectionContent>
      <p>Bassist Jon Hawes was born in England, spent his formative years playing trombone, guitar, piano &amp; bass in Massachusetts, studied music at the University of Rochester and has lived in Hawaii since 2003 where he performs mostly on bass guitar and double bass. </p>
      <p> Since relocating to Hawaii he has worked with Hawaii-based artists such as Henry Kapono, Cecilio & Kapono, Mike Love, Kalapana, John Cruz, Brother Noland, Teresa Bright, Paula Fuga & more, as well as backing up visiting artists such as Michael McDonald, Mick Fleetwood, Deodato & David Choi & more. He has played on numerous Na Hoku award-winning albums and also been nominated for a Grammy with Henry Kapono.  His touring life has led him all over the US, plus Australia, Costa Rica, Japan, Brazil, Guam, The Philippines and Europe.  
      </p>
      <p>In addition to performing, Jon is a composer and has written &amp; produced music for TV commercials and short films.  </p>
      <p>
      “I truly believe in the healing power of music and feel it is my calling to help spread a positive message far and wide during these chaotic and challenging times.”
      </p>
      </SectionContent>
    </Main>
    </React.Fragment>
  )
}