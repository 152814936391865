import React from 'react';
import styled from 'styled-components'

import { Main } from '../../App'
import { Hero } from '../Hero/Hero'
import { VideoCard } from './VideoCard'
import { color, size, MainHeadingShadowed, SectionHeading, SectionContent } from '../../Styles/styles'

const TwoColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  width: 100%;

  @media ${ size.medium } {

  flex-direction: row;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: ${ props => props.flex ? props.flex : 1 };
  margin: 0;
`

const CustomHeading = styled(SectionHeading)`
  text-align: left;
  border-bottom: 2px solid ${ color.green };
  margin-right: 2rem;
`

export const Demo = (props) => {

  return ( 
    <React.Fragment>
      <Hero heroImage="jh_woodford1_1620.jpg">
        <MainHeadingShadowed>Demo Reel</MainHeadingShadowed>
      </Hero>
      <Main>
           
        <SectionContent>Here are some films and commcercials I've worked on. I composed and produced each of these scores.</SectionContent>
        
        <TwoColumnContainer>
          <Column>
            <CustomHeading>Films</CustomHeading>
            <VideoCard  title="Crystal" 
                        url="https://www.youtube.com/embed/IxNWxmJzMf0"
                        text="This was a submission for Filmaka."
                        director="Brett Wagner" />
            <VideoCard  title="The Beauty Queen" 
                        url="https://www.youtube.com/embed/QvvvLovaPzA" 
                        text=""
                        director="Brett Wagner"  />
            <VideoCard title="And Tomorrow" url="https://www.youtube.com/embed/jB-ZsdZ29S4" 
                        text=""
                        director="Brett Wagner"  />
          </Column>
          <Column>
            <CustomHeading>Commercials</CustomHeading>
            <VideoCard title="First Hawaiian Bank" url="https://www.youtube.com/embed/1u9bkf6rw-s" 
                        text=""
                        director="Brett Wagner"  />
            <VideoCard title="Hawaiian Airlines" url="https://www.youtube.com/embed/x3Xx6eTKvVY" 
                        text="National spot for Hawaiian Airlines."
                        director="Brett Wagner" />
          </Column>
        </TwoColumnContainer>   
      </Main>
    </React.Fragment>
  )
 
}